const __request = require(`./__request/__request_contentType_json`)

// http://192.168.43.143:8765/swagger-ui.html#/t-us-notice-controller/addUsingPOST
const request = (params) => {
    if (!params) params = {}

    const noticeContent = params.noticeContent || ''
    const noticeTitle = params.noticeTitle || ''

    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/consumer/notice/add',
        data: {
            noticeContent,
            noticeTitle,
        }
    }
    return __request(pParameter).then(data => {
        if (data.code === '000000') {
            return data
        }
    }).catch((err) => {
        console.log('接口 consumer_notice_add 异常')
        throw(err)
    })
}
export default request