import consumer_notice_add from '@/lib/data-service/haolv-default/consumer_notice_add.js';

export default {
  data () {
    return {
      form: {
        noticeContent: '',
        noticeTitle: '',
      },
    }
  },
  methods: {
    // 确定
    onSubmit() {
      if (this.form.noticeTitle === '') {
        this.$message.warning('请输入公告标题')
        return
      }

      if (this.form.noticeContent === '') {
        this.$message.warning('请输入公告内容')
        return
      }
      consumer_notice_add(this.form).then(() => {
        this.$router.replace('/admin/notice')
        this.$message({
          type: 'success',
          message: '发布成功'
        });
      })
    },
  },
  mounted () {
  },
  activated () {
    this.form = {
      noticeContent: '',
      noticeTitle: '',
    }
  },
  deactivated () {

  }
}